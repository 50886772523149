import ActionsButton from "@ipgd-gauge/actions-button";
import { isAllowedAction } from "@ipgd-gauge/utils";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import ExcelIcon from "../../icons/Excel";
import FiltersIcon from "../../icons/Filters";
import { TasksMonitoringExportExcel } from "../../util/apis";
import { RSM3252_TASKS_LIST_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";
import TasksListFilter from "./listing/Filters";
import TasksListTable from "./listing/index";
import { EXPORT_ACTION } from "../../util/constants/actions";

const TasksList = () => {
    const { t } = useTranslation("tasksList");

    const searchTextRef = useRef();
    const tasksListTableRef = useRef();
    const tasksListFilterRef = useRef();
    const user = getUser();

    const _getActions = () => {
        const actions = [];
        if (isAllowedAction(getUser(), RSM3252_TASKS_LIST_FORM_ID, EXPORT_ACTION)) {
            actions.push({ id: `export-excel-sheet`, icon: <ExcelIcon />, label: t('export'), action: () => TasksMonitoringExportExcel({ searchValue: searchTextRef.current.getSearchText(), ...tasksListFilterRef.current?.getFilterData() || {} }, "export-excel-sheet") })
        }
        return actions;
    }

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={RSM3252_TASKS_LIST_FORM_ID} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('transaction') + ' , ' + t('task_details') + ' , ' + t('user_list')}
                        search={() => tasksListTableRef.current.refresh()}
                        formId={RSM3252_TASKS_LIST_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => tasksListFilterRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <TasksListTable
                user={user}
                tasksListTableRef={tasksListTableRef}
                tasksListFilterRef={tasksListFilterRef}
                searchTextRef={searchTextRef}
            />
            <ActionsButton actions={_getActions()} />

            <TasksListFilter ref={tasksListFilterRef} user={user} tasksListTableRef={tasksListTableRef} />
        </>
    );
}

export default TasksList;