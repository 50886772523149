import { DateFromToInput } from "@ipgd-gauge/date-picker";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import { getCitiesLov, getCommonCustomers, getCommonPartiesLov, getCommonPropertyTypes, getDomainValues, getEvaluationFunctions, getRegionsLov, getRsmStatus, getTaskDescriptionLOV } from "../../../util/apis";
import { DOMAIN_PROPORTIES_PARTIES_TYPE, STATUS_ACTIVE } from "../../../util/constants";
import { RSM3250_VALUATION_REPORTS_FORM_ID } from "../../../util/constants/forms";

const EstimationReportsFilter = (props, ref) => {
    const { estimationReportsTableRef } = props;

    const { t } = useTranslation();
    const advanceFilterRef = useRef();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => Object.assign(filterData, {});

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ getFilterData, open }));

    return (
        <AdvanceFilter
            ref={advanceFilterRef}
            id="estimationReportsFilter"
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => estimationReportsTableRef?.current?.refresh()}
            numOfRows={6}
            formId={RSM3250_VALUATION_REPORTS_FORM_ID}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('estimationReports:status')}</label>
                            <FrmSelectMenu
                                id="status-filter"
                                value={filterData.status}
                                onChange={value => _handleDataChanged("status", value)}
                                api={getRsmStatus}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationReports:trx_date_form_to')}</label>
                            <DateFromToInput
                                id="trx-from-to-filter"
                                fromValue={filterData?.fromDate}
                                toValue={filterData?.toDate}
                                fromOnChange={(value) => _handleDataChanged("fromDate", value)}
                                toOnChange={(value) => _handleDataChanged("toDate", value)}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationReports:approval_date_from_to')}</label>
                            <DateFromToInput
                                id="approval-date-from-to-filter"
                                fromValue={filterData?.appFromDate}
                                toValue={filterData?.appToDate}
                                fromOnChange={(value) => _handleDataChanged("appFromDate", value)}
                                toOnChange={(value) => _handleDataChanged("appToDate", value)}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationReports:execution_date_from_to')}</label>
                            <DateFromToInput
                                id="exec-date-from-to-filter"
                                fromValue={filterData?.execFromDate}
                                toValue={filterData?.execToDate}
                                fromOnChange={(value) => _handleDataChanged("execFromDate", value)}
                                toOnChange={(value) => _handleDataChanged("execToDate", value)}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationReports:party_type')}</label>
                            <FrmSelectMenu
                                id="party-type-filter"
                                value={filterData.partyType}
                                onChange={value => _handleDataChanged("partyType", value)}
                                api={() => getDomainValues(DOMAIN_PROPORTIES_PARTIES_TYPE)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationReports:party')}</label>
                            <FrmSelectMenu
                                id="party-filter"
                                value={filterData.party}
                                onChange={value => _handleDataChanged("party", value)}
                                api={() => getCommonPartiesLov(null, null, null, null, filterData.party, null, null, 1, null)}
                                descLo={['nameLo']}
                                descFo={['nameFo']}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationReports:customer')}</label>
                            <FrmSelectMenu
                                id="customer-filter"
                                value={filterData.customer}
                                onChange={value => _handleDataChanged("customer", value)}
                                defualtValueId={filterData.customers}
                                defualtValueApi={() => getCommonCustomers(filterData.customers, null, null, true, false, false, [], true)}
                                api={(searchValue) => getCommonCustomers(null, null, searchValue, true, false, false, [], true)}
                                descLo={['code', 'descLo']}
                                descFo={['code', 'descFo']}
                                isAsync={true}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationReports:property_type')}</label>
                            <FrmSelectMenu
                                id="property-type-filter"
                                value={filterData.propertyType}
                                onChange={value => _handleDataChanged("propertyType", value)}
                                api={() => getCommonPropertyTypes(null, null, true, false, false, false, true, filterData.propertyType)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationReports:task')}</label>
                            <FrmSelectMenu
                                id="task-filter"
                                value={filterData.task}
                                onChange={value => _handleDataChanged("task", value)}
                                api={() => getTaskDescriptionLOV(filterData.task)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationReports:workflow')}</label>
                            <FrmSelectMenu
                                id="workflow-filter"
                                value={filterData?.workflow}
                                onChange={value => _handleDataChanged("workflow", value)}
                                api={() => getEvaluationFunctions(filterData?.workflow, null, null, null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationReports:region')}</label>
                            <FrmSelectMenu
                                id="region-filter"
                                value={filterData.region}
                                onChange={value => _handleDataChanged("region", value)}
                                api={() => getRegionsLov(filterData.region, null, true)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationReports:city')}</label>
                            <FrmSelectMenu
                                id="city-filter"
                                value={filterData.city}
                                onChange={value => _handleDataChanged("city", value)}
                                defualtValueId={filterData.city}
                                defualtValueApi={() => getCitiesLov(filterData.ecdId)}
                                api={(searchValue) => getCitiesLov(null, null, null, null, searchValue)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                isMulti={true}
                                isAsync={true}
                                portalTarget={document.body}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    );
}

export default forwardRef(EstimationReportsFilter);